/* global $ */
class App {
    bootstrap;

    $paramNames = [];
    $expirationTime = 0;

    constructor() {
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($(".toast"));
        this.initForms($("form"));
        this.initMatrixBlocks();
        this.initStickyHeader();
        $(document).scroll(() => {
            this.initStickyHeader();
        });
        $("body").css("opacity", 1);
        console.log("App initialized");

        this.handleURLParams(this.$paramNames, this.$expirationTime);
    }

    initStickyHeader() {
        if ($(window).scrollTop() > 300) {
            $('#header').addClass('scrolled');
        } else {
            $('#header').removeClass('scrolled');
        }
    }

    getBootstrap() {
        if (!this.bootstrap) {
            this.bootstrap = import(
                /* webpackChunkName: "bootstrap" */ "bootstrap"
            );
        }
        return this.bootstrap;
    }

    disableLogger() {
        if (window.Globals.env == "production") {
            console.log = function () {};
        }
    }

    initForms($forms) {
        if ($forms.length) {
            import(/* webpackChunkName: "form" */ "./components/form").then(
                (chunk) => {
                    $.each($forms, (i, elem) => {
                        new chunk.Form($(elem));
                    });
                }
            );
        }
    }

    initMatrixBlocks()
    {
        if ($('.matrix-block').length) {
            import(/* webpackChunkName: "matrixContent" */ './components/matrixContent');
        }
    }

    initToasts($elems) {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Toast(elem).show();
                });
            });
        }
    }

    initTooltips($elems) {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Tooltip(elem);
                });
            });
        }
    }

    addAlert(message, $elem, type = "danger", dismissible = false) {
        let _class = "alert alert-" + type;
        if (dismissible) {
            _class += " alert-dismissible fade show";
            message +=
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
        }
        $('<div class="' + _class + '">' + message + "</div>").appendTo($elem);
    }

    addAlerts(messages, $elem, type = "danger", dismissible = false) {
        for (let i in messages) {
            this.addAlert(messages[i], $elem, type, dismissible);
        }
    }

    handleURLParams(paramNames = [], expirationTime = 0) {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        if (paramNames.length === 0) return;

        paramNames.forEach((param) => {
            if (params.has(param)) {
                const value = params.get(param);
                if (expirationTime !== 0) {
                    const expiry = Date.now() + expirationTime * 1000;
                    localStorage.setItem(
                        param,
                        JSON.stringify({ value, expiry })
                    );
                } else {
                    localStorage.setItem(param, value);
                }
            }
        });
    }

    getStoredParams(paramNames = []) {
        const result = {};

        paramNames.forEach((param) => {
            const storedValue = localStorage.getItem(param);
            if (storedValue) {
                try {
                    const parsedValue = JSON.parse(storedValue);
                    if (parsedValue.expiry && parsedValue.expiry < Date.now()) {
                        localStorage.removeItem(param);
                    } else {
                        result[param] = parsedValue.value || storedValue;
                    }
                } catch (e) {
                    result[param] = storedValue;
                }
            }
        });

        return result;
    }
}

export default App;
